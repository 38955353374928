import { useEffect, useState } from "react";

export const ToTopButton = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setVisible(window.pageYOffset >= 800)
      );
    }
  }, []);

  const onTopPressed = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <button
      onClick={onTopPressed}
      className={`totop ${visible ? "totop-vissible" : ""}`}
    >
      TOP
    </button>
  );
};
