/* eslint-disable react/jsx-no-target-blank */
import { useQuery, gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useLocation } from "react-router";

export const Header = () => {
  const [small, setSmall] = useState(false);

  const { data } = useQuery(gql`
    query GetMenuItems {
      menuItemCollection(order: order_ASC) {
        items {
          title
          path
          externalLink
        }
      }
    }
  `);

  const location = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset >= 10)
      );
    }
  }, []);

  return (
    <header>
      <div className="fredia-header-navbar">
        <div className="container fredia-navbar-container">
          <div
            className={`fredia-navigation-wrap fredia-start-header ${
              small ? "scroll-on" : "start-style"
            }`}
          >
            <Navbar expand="md">
              <Container>
                <Navbar.Brand href="/">
                  <img src="/images/logo.png" alt="" />
                </Navbar.Brand>
                <Navbar.Toggle
                  className="navbar-toggler"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                />
                <Navbar.Collapse
                  id="navbarSupportedContent"
                  className="collapse navbar-collapse"
                >
                  <Nav className="navbar-nav ml-auto py-4 py-md-0">
                    {data?.menuItemCollection.items.map((item: any) => (
                      <Nav.Link
                        className={`nav-item pl-4 pl-md-0 ml-0 ml-md-4 ${
                          item.path === "/"
                            ? location.pathname === "/"
                              ? "active"
                              : ""
                            : location.pathname.startsWith(item.path)
                            ? "active"
                            : ""
                        }`}
                        key={item.title}
                        href={item.path}
                        target={
                          item.externalLink === true ? "_blank" : undefined
                        }
                        rel={
                          item.externalLink === true ? "noreferrer" : undefined
                        }
                      >
                        {item.title}
                      </Nav.Link>
                    ))}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </div>
      </div>
    </header>
  );
};
