// Core modules imports are same as usual
import { Navigation, EffectCreative, EffectCoverflow } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import { ServicesGrid } from "../services/ServicesGrid";
import { useQuery, gql } from "@apollo/client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useState } from "react";
import { useWindowWidth } from "@react-hook/window-size";

const SMALL_SCREEN_WIDTH = 991;

export const Homepage = () => {
  const [activeTestimonialIndex, setActiveTestimonialIndex] =
    useState<number>(0);

  const currentWidth = useWindowWidth();

  const { data } = useQuery(gql`
    query GetHomePage {
      mainPageCollection(where: { title: "Home" }) {
        items {
          title
          content {
            json
          }
        }
      }
    }
  `);

  const { data: testimonialsData } = useQuery(gql`
    query GetTestimonials {
      testimonialCollection {
        items {
          name
          service
          content {
            json
          }
          photo {
            url
          }
        }
      }
    }
  `);

  const homepageContent = data?.mainPageCollection.items[0];

  return (
    <div className="slider">
      <Swiper
        modules={[Navigation, EffectCreative]}
        spaceBetween={0}
        speed={1000}
        style={{
          backgroundColor: "#000000",
          height: "100vh",
        }}
        slidesPerView={1}
        navigation={{
          prevEl: ".prev-main-slider-ctrl",
          nextEl: ".next-main-slider-ctrl",
        }}
        autoplay
        loop
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
      >
        <SwiperSlide>
          <div
            style={{
              backgroundImage: `url(
                  "/images/1920x1280.jpg"
                )`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: "100vh",
              display: "flex",
            }}
          >
            <div
              className="container"
              style={{
                alignSelf: "center",
              }}
            >
              <div className="row justify-content-left">
                <div className="col-md-7">
                  <div className="caption left">
                    <h1>Welkom bij Esthetiek Royals!</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              backgroundImage: `url(
                  "/images/1920x1280.jpg"
                )`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: "100vh",
            }}
          ></div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              backgroundImage: `url(
                  "/images/1920x1280.jpg"
                )`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: "100vh",
            }}
          ></div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              backgroundImage: `url(
                  "/images/1920x1280.jpg"
                )`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: "100vh",
            }}
          ></div>
        </SwiperSlide>
        <div className="setone setwo">
          <div className="swiper-button-next swiper-nav-ctrl next-ctrl next-main-slider-ctrl cursor-pointer">
            <i className="ti-angle-right"></i>
          </div>
          <div className="swiper-button-prev swiper-nav-ctrl prev-ctrl prev-main-slider-ctrl cursor-pointer">
            <i className="ti-angle-left"></i>
          </div>
        </div>
        <div className="swiper-pagination top botm"></div>
        <div className="social-icon">
          <a
            href="https://www.instagram.com/esthetiek.royals/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="ti-instagram"></i>
          </a>
          <a
            href="https://www.facebook.com/esthetiekroyals"
            target="_blank"
            rel="noreferrer"
          >
            <i className="ti-facebook"></i>
          </a>
        </div>
      </Swiper>

      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-30">
              <span>By Jill Huybrechts</span>
              <h1>Esthetiek Royals</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2">
                  <span className="line-one"></span>
                </div>
                <div className="col-md-7 mb-30">
                  {!!homepageContent &&
                    documentToReactComponents(homepageContent.content.json)}
                </div>
              </div>
            </div>
          </div>
          <ServicesGrid />
        </div>
      </div>

      {testimonialsData && (
        <div
          className="testimonials section-padding bg-img bg-fixed"
          style={{
            backgroundImage: `url(
            "/images/1920x1280.jpg"
          )`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>Wat klanten zeggen</h1>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                display: "flex",
              }}
            >
              <iframe
                title="Esthetiek Royals booking widget"
                src="./reviews.html"
                style={{
                  width: "415px",
                  height: "172px",
                  border: "none",
                  backgroundColor: "#ffffff",
                }}
              />
            </div>

            <div className="row">
              <div className="owl-carousel owl-theme col-md-12">
                <Swiper
                  effect={"coverflow"}
                  centeredSlides={true}
                  modules={[Navigation, EffectCoverflow]}
                  spaceBetween={55}
                  navigation={{
                    prevEl: ".prev-testimonial-ctrl",
                    nextEl: ".next-testimonial-ctrl",
                  }}
                  speed={1000}
                  slidesPerView={currentWidth < SMALL_SCREEN_WIDTH ? 1 : 3}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 80,
                    modifier: 1,
                    slideShadows: false,
                  }}
                  autoplay
                  loop
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  onActiveIndexChange={(swiper) => {
                    const correctedIndex = swiper.activeIndex - 3;
                    const finalIndex =
                      correctedIndex >=
                      testimonialsData.testimonialCollection.items.length
                        ? correctedIndex -
                          testimonialsData.testimonialCollection.items.length
                        : correctedIndex;

                    setActiveTestimonialIndex(finalIndex);
                  }}
                >
                  {testimonialsData.testimonialCollection.items.map(
                    (testimonial: any, index: number) => {
                      return (
                        <SwiperSlide key={`testimonial_${index}`}>
                          <div
                            className="item-box"
                            style={
                              index === activeTestimonialIndex ||
                              currentWidth < SMALL_SCREEN_WIDTH
                                ? {
                                    opacity: 1,
                                    borderBottomColor: "#c98e82",
                                    borderBottomWidth: "3px",
                                    borderBottomStyle: "solid",
                                  }
                                : { opacity: 0.6 }
                            }
                          >
                            <span className="quote">
                              <img src="/images/quot.png" alt="" />
                            </span>
                            {documentToReactComponents(
                              testimonial.content.json
                            )}
                            <div className="info">
                              <div className="author-img">
                                <img
                                  src={testimonial.photo?.url ?? ""}
                                  alt=""
                                />
                              </div>
                              <div className="cont">
                                <h6>{testimonial.name}</h6>
                                <span>{testimonial.service}</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
              </div>
              <div className="setone setwo">
                <div className="swiper-button-next swiper-nav-ctrl next-ctrl next-testimonial-ctrl cursor-pointer">
                  <i className="ti-angle-right"></i>
                </div>
                <div className="swiper-button-prev swiper-nav-ctrl prev-ctrl prev-testimonial-ctrl cursor-pointer">
                  <i className="ti-angle-left"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
