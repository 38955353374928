import React from "react";
import ReactDOM from "react-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import { Homepage } from "./home/Homepage";
import reportWebVitals from "./reportWebVitals";
import { apolloClient } from "./apollo";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Services } from "./services/Services";
import { Header } from "./snippets/Header";
import { Footer } from "./snippets/Footer";
import { Service } from "./services/Service";
import { Gallery } from "./gallery/Gallery";
import { ToTopButton } from "./snippets/ToTopButton";
import { Contact } from "./contact/Contact";
import { Appointment } from "./appointment/Appointment";
import { PrivacyPolicy } from "./privacyPolicy/PrivacyPolicy";
import { TermsAndConditions } from "./termsAndConditions/TermsAndConditions";
import { CustomerService } from "./customerService/CustomerService";

const App = () => (
  <div>
    <Header />
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/services" element={<Services />} />
      <Route path="/services/:title/:id" element={<Service />} />
      <Route path="/appointment" element={<Appointment />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/customer-service" element={<CustomerService />} />
    </Routes>
    <ToTopButton />
    <Footer />
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <SimpleReactLightbox>
          <App />
        </SimpleReactLightbox>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
