import { gql, useQuery } from "@apollo/client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

export const Contact = () => {
  const { loading, error, data } = useQuery(gql`
    query GetContactPage {
      mainPageCollection(where: { title: "Contact" }) {
        items {
          title
          content {
            json
          }
          mediaCollection {
            items {
              url
            }
          }
        }
      }
    }
  `);

  if (loading || error) {
    return <div />;
  }

  const contactContent = data.mainPageCollection.items[0];

  return (
    <div>
      <div
        className="ready banner-padding bg-img bg-fixed valign"
        style={{
          backgroundImage: `url(
            "/images/1920x1280.jpg"
          )`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-right">
                <div className="title mt-60">
                  <h1 className="mb-0">{contactContent.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>{contactContent.title}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <span className="line-one"></span>
            </div>
            <div className="col-md-7 mb-30">
              {!!contactContent.content &&
                documentToReactComponents(contactContent.content.json)}
            </div>
          </div>
          <div className="row">
            <LoadScript googleMapsApiKey="AIzaSyA6KNXpUKNk-GkcMNKkJM5A3SRFlSoibPs">
              <GoogleMap
                mapContainerStyle={{ height: "600px", width: "100%" }}
                center={{
                  lat: 50.908696,
                  lng: 4.882083,
                }}
                zoom={19}
              >
                {/* Child components, such as markers, info windows, etc. */}
                <>
                  <Marker
                    position={{
                      lat: 50.908696,
                      lng: 4.882183,
                    }}
                    animation={0.0}
                    icon={"/images/map-marker.png"}
                  />
                </>
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </div>
    </div>
  );
};
