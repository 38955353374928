import { gql, useQuery } from "@apollo/client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ServicesGrid } from "./ServicesGrid";

export const Services = () => {
  const {
    loading: pageLoading,
    error: pageError,
    data: pageData,
  } = useQuery(gql`
    query GetServicesPage {
      mainPageCollection(where: { title: "Behandelingen" }) {
        items {
          title
          content {
            json
          }
        }
      }
    }
  `);

  if (
    pageLoading ||
    pageError ||
    pageData.mainPageCollection.items.length !== 1
  ) {
    return <div />;
  }

  const servicesContent = pageData.mainPageCollection.items[0];

  return (
    <div>
      <div
        className="ready banner-padding bg-img bg-fixed valign"
        style={{
          backgroundImage: `url(
            "/images/1920x1280.jpg"
          )`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-right">
                <div className="title mt-60">
                  <h1 className="mb-0">{servicesContent.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-30">
              <h2>{servicesContent.title}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2">
                  <span className="line-one"></span>
                </div>
                <div className="col-md-7 mb-30">
                  {!!servicesContent.content &&
                    documentToReactComponents(servicesContent.content.json)}
                </div>
              </div>
            </div>
          </div>
          <ServicesGrid />
        </div>
      </div>
    </div>
  );
};
