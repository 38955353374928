import { gql, useQuery } from "@apollo/client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export const Appointment = () => {
  const { loading, error, data } = useQuery(gql`
    query GetContactPage {
      mainPageCollection(where: { title: "Boek afspraak" }) {
        items {
          title
          content {
            json
          }
        }
      }
    }
  `);

  if (loading || error) {
    return <div />;
  }

  const appointmentContent = data.mainPageCollection.items[0];

  return (
    <div>
      <div
        className="ready banner-padding bg-img bg-fixed valign"
        style={{
          backgroundImage: `url(
            "/images/1920x1280.jpg"
          )`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-right">
                <div className="title mt-60">
                  <h1 className="mb-0">{appointmentContent.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>{appointmentContent.title}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <span className="line-one"></span>
            </div>
            <div className="col-md-7 mb-30">
              {!!appointmentContent.content &&
                documentToReactComponents(appointmentContent.content.json)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
