import { gql, useQuery } from "@apollo/client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { SRLWrapper } from "simple-react-lightbox";

export const Gallery = () => {
  const { loading, error, data } = useQuery(gql`
    query GetGalleryPage {
      mainPageCollection(where: { title: "Galerij" }) {
        items {
          title
          content {
            json
          }
          mediaCollection {
            items {
              url
            }
          }
        }
      }
    }
  `);

  if (loading || error) {
    return <div />;
  }

  const galleryContent = data.mainPageCollection.items[0];

  return (
    <SRLWrapper>
      <div>
        <div
          className="ready banner-padding bg-img bg-fixed valign"
          style={{
            backgroundImage: `url(
            "/images/1920x1280.jpg"
          )`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-right">
                  <div className="title mt-60">
                    <h1 className="mb-0">{galleryContent.title}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>{galleryContent.title}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <span className="line-one"></span>
              </div>
              <div className="col-md-7 mb-30">
                {!!galleryContent.content &&
                  documentToReactComponents(galleryContent.content.json)}
              </div>
            </div>
            <div className="row">
              {galleryContent.mediaCollection.items.length > 0 &&
                galleryContent.mediaCollection.items.map((image: any) => {
                  return (
                    <div className="col-md-4 gallery-item" key={image.url}>
                      <a href={image.url} title="Title" className="img-zoom">
                        <img
                          src={image.url}
                          className="img-fluid mx-auto d-block"
                          alt=""
                          style={{
                            height: "275px",
                            objectPosition: "center",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </SRLWrapper>
  );
};
