export const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-section">
          <div className="row">
            <div className="col">
              <h6>Adres</h6>
              <p>
                <b>Esthetiek Royals</b>
              </p>
              <p>
                Leuvensesteenweg 147
                <br />
                3390 Sint-Joris-Winge
              </p>
            </div>
            <div className="col">
              <h6>Openingsuren</h6>
              <p>
                Ma/di: op afspraak
                <br />
                Wo: op afspraak (tot 17u)
                <br />
                Do/vr: op afspraak
                <br />
                Za: op afspraak (tot 16u)
                <br />
                Zo: gesloten
              </p>
            </div>
            <div className="col">
              <h6>Contact</h6>
              <p>
                E-mailadres:
                <br />
                <a href="mailto:esthetiek_royals@hotmail.com">
                  esthetiek_royals@hotmail.com
                </a>
              </p>
              <p>
                GSM:
                <br />
                0476 56 45 36
              </p>
              <div className="btn-cont">
                <a href="mailto:esthetiek_royals@hotmail.com">
                  <span>Contacteer mij!</span>
                </a>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="text-left">
                <p>
                  © 2021 Esthetiek Royals |{" "}
                  <a href="/privacy-policy">Privacy policy</a> |{" "}
                  <a href="/terms-and-conditions">Algemene voorwaarden</a> |{" "}
                  <a href="/customer-service">Klantenservice</a>
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-right">
                <ul className="footer-social-link">
                  <li>
                    <a
                      href="https://www.facebook.com/esthetiekroyals"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="ti-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/esthetiek.royals/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="ti-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
