import { gql, useQuery } from "@apollo/client";

export const ServicesGrid = () => {
  const { loading: servicesLoading, data: servicesData } = useQuery(gql`
    query GetServicesPage {
      serviceCollection(order: order_ASC) {
        items {
          sys {
            id
          }
          title
          content {
            json
          }
          mediaCollection {
            items {
              url
            }
          }
        }
      }
    }
  `);

  if (servicesLoading) {
    return <div />;
  }

  return (
    <div className="row services">
      {!!servicesData &&
        servicesData.serviceCollection.items.map((service: any) => (
          <div className="col-md-4 mb-15" key={service.sys.id}>
            <a href={`/services/${service.title}/${service.sys.id}`}>
              <div className="item">
                <div className="position-re o-hidden">
                  <img
                    src={
                      service.mediaCollection.items.length >= 1
                        ? service.mediaCollection.items[0].url
                        : "images/1000x800.jpg"
                    }
                    style={{
                      height: "270px",
                      objectPosition: "center",
                      objectFit: "cover",
                    }}
                    alt={service.title}
                  />
                </div>
                <div className="con">
                  <h5>{service.title}</h5>
                </div>
              </div>
            </a>
          </div>
        ))}
    </div>
  );
};
